import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Outlet, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';

const AppRouter: React.FC = () => {
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const myToken = localStorage.getItem('myToken');
    const user = localStorage.getItem('myUserID');

    if (user && myToken) {
      setIsUserLoggedIn(true);
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'myToken') {
        setIsUserLoggedIn(!!localStorage.getItem('myToken'));
      }
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isLoading]);

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate('/');
    }
  }, [navigate, isUserLoggedIn]);

  // PrivateRoute component to handle protected routes
  const PrivateRoute = () => {
    return isUserLoggedIn ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isUserLoggedIn ? (
            <Navigate to="/" />
          ) : (
            <Login isLoading={isLoading} setIsLoading={setIsLoading} />
          )
        }
      />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
