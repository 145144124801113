import React from 'react';

import { useAuth } from '../hooks/useAuth';
import QrScanner from '../components/QrScanner';
import logo from '../assets/afex-logo-2024.png';

import { Button } from '@mui/material';

const Home: React.FC = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  return (
    <div
      style={{
        padding: 0,
        margin: '1rem',
      }}
    >
      <div style={{ margin: '0', width: '100%', textAlign: 'right' }}>
        <Button
          variant="text"
          onClick={handleLogout}
          style={{ position: 'relative' }}
        >
          Logout
        </Button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src={logo}
          alt={'afex-logo'}
          style={{ height: '130px', width: 'auto', marginTop: '1rem' }}
        />
      </div>
      <div style={{ padding: '1rem' }}>
        {/* Add content specific to the Home page */}
        {/* <h2 style={{ textAlign: 'center', marginBottom: '1rem' }}>
          Scan QR Code
        </h2> */}
        {/* <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
          Place QR code inside the frame to scan.
        </p> */}
        <QrScanner />
      </div>
    </div>
  );
};

export default Home;
