import { ReactNode, createContext, useState } from 'react';
import { AuthValuesType, ErrCallbackType, LoginParams } from './types';
import axios from 'axios';

const defaultProvider: AuthValuesType = {
  loading: true,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  // const handleLogin = (
  //   params: LoginParams,
  //   errorCallback?: ErrCallbackType,
  // ) => {
  //   axios
  //     .post(process.env.REACT_APP_API_KEY!, {
  //       query: ,
  //       variables: params,
  //     })
  //     .then(async (data) => {
  //       if (data.data.errors) {
  //         errorCallback && errorCallback(data.data.errors[0].message!);
  //       } else {
  //         localStorage.setItem('myToken', data.data.data.loginAccessPass);
  //         errorCallback && errorCallback({ loading: false });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (errorCallback) errorCallback(err);
  //     });
  // };

  const handleLogin = (
    params: LoginParams,
    errorCallback?: ErrCallbackType,
  ) => {
    axios
      .post(process.env.REACT_APP_API_KEY + '/access-pass/login', {
        ...params,
      })
      .then(async ({ data }: any) => {
        if (data.errors) {
          errorCallback && errorCallback(data.data.errors[0].message!);
        } else {
          localStorage.setItem('myUserID', params.uid);
          localStorage.setItem('myToken', data.token);
          errorCallback && errorCallback({ loading: false });
        }
      })
      .catch((err) => {
        console.error(err);
        if (errorCallback) errorCallback(err);
      });
  };

  const handleLogout = () => {
    window.localStorage.removeItem('myToken');
    window.localStorage.removeItem('myUserID');
  };

  const values = {
    loading,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
