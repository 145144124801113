// import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useAuth } from '../hooks/useAuth';
import logo from '../assets/afex-logo-2024.png';
import {
  Box,
  Button,
  Card,
  FormControl,
  // FormHelperText,
  Icon,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';

const defaultValues = {
  uid: '',
  password: '',
};

interface FormData {
  uid: string;
  password: string;
}

interface Props {
  isLoading: any;
  setIsLoading: any;
}

const Login = ({ isLoading, setIsLoading }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [buttonLoading, setIsButtonLoading] = useState<boolean>(false);
  // const classes = useStyles();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const auth = useAuth();

  // const hardcodedUsers = [
  //   { uid: 'user1', password: 'Password123!' },
  //   { uid: 'user2', password: 'Password123!' },
  // ];

  const onSubmit = async (data: FormData) => {
    const { uid, password } = data;
    setIsLoading(true);
    setIsButtonLoading(true);
    try {
      await auth.login({ uid, password }, (bool) => {
        setError('uid', {
          type: 'manual',
          message: 'User ID or Password is invalid',
        });
        setError('password', {
          type: 'manual',
          message: 'User ID or Password is invalid',
        });

        setIsButtonLoading(false);

        if (bool) {
          setIsLoading(bool);
        }
      });
    } catch (error) {
      console.log(error);
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  // const onSubmit = async (data: FormData) => {
  //   const { uid, password } = data;
  //   localStorage.setItem('myUserID', uid);
  //   setIsLoading(true);
  //   setIsButtonLoading(true);

  //   const user = hardcodedUsers.find(
  //     (user) => user.uid === uid && user.password === password,
  //   );

  //   if (user) {
  //     setIsLoading(false);
  //     setIsButtonLoading(false);
  //     // Handle successful login here
  //   } else {
  //     setError('uid', {
  //       type: 'manual',
  //       message: 'User ID or Password is invalid',
  //     });
  //     setError('password', {
  //       type: 'manual',
  //       message: 'User ID or Password is invalid',
  //     });
  //     setIsButtonLoading(false);
  //     setIsLoading(false);
  //   }
  // };

  return (
    <Box
      sx={{
        px: 2,
        py: 8,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ width: '20rem' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            sx={{ p: 2 }}
          >
            <img
              src={logo}
              alt={'afex-logo'}
              style={{ height: 'auto', width: '140px', marginTop: '1rem' }}
            />

            <FormControl fullWidth>
              <Controller
                name="uid"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.uid)}
                    placeholder="Enter User ID"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    placeholder="Enter Password"
                    onChange={onChange}
                    id="auth-login-v2-password"
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e: any) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon>
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </FormControl>

            {/* {errors.uid && (
              <FormHelperText>{errors.uid.message}</FormHelperText>
            )} */}

            <Button
              disabled={buttonLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{
                backgroundColor: '#3F7DB2',
                color: '#fff',
                margin: '1rem auto',
              }}
            >
              {buttonLoading ? 'Loading' : 'Login'}
            </Button>
          </Stack>
        </form>
      </Card>
    </Box>
  );
};

export default Login;
