import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { CircularProgress, TextField } from '@mui/material';
// import debounce from 'lodash/debounce';

const QrScanner = () => {
  const [qrscan, setQrscan] = useState<string>('');
  const [scanError, setScanError] = useState<any>('');
  const [bool, setBool] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const textFieldRef = useRef<HTMLInputElement>(null);

  const userID = localStorage.getItem('myUserID');
  const token = localStorage.getItem('myToken');

  const handleFocus = ({ value }: { value: string }) => {
    setBool(true);
    try {
      setQrscan(value);
      setBool(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Debounced onSubmit function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedOnSubmit = useCallback(
  //   debounce(async (scannedData) => {
  //     if (scannedData) {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_QR_API_KEY}/${scannedData}`,
  //         );

  //         console.log(response.data);

  //         if (response.data.status === 'success') {
  //           Swal.fire({
  //             title: 'Success',
  //             icon: 'success',
  //             html: `
  //               <p>Visitor Details</p>
  //               <br />
  //               <p>Name: ${response.data.first_name} ${
  //               response.data.last_name
  //             }</p>
  //               <p>Position: ${
  //                 response.data.company_role ? response.data.company_role : 'NA'
  //               }</p>
  //               <p>Company Name: ${
  //                 response.data.company_name ? response.data.company_name : 'NA'
  //               }</p>
  //             `,
  //             timer: 1300,
  //             timerProgressBar: true,
  //             showConfirmButton: false,
  //             willClose: () => {
  //               setQrscan('');
  //               // setStartScan(true);
  //             },
  //           });
  //         }
  //       } catch (error) {
  //         setScanError(error);
  //       }
  //     }
  //   }, 500),
  //   [], // Dependencies to reset debounce
  // );

  // useEffect(() => {
  //   if (qrscan) {
  //     debouncedOnSubmit(qrscan);
  //   }
  //   // Cleanup debounce on unmount
  //   return () => debouncedOnSubmit.cancel();
  // }, [qrscan, debouncedOnSubmit]);

  const onSubmit = async () => {
    if (qrscan !== '') {
      setIsLoading(true);
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_QR_API_KEY}/${qrscan}`,
        });

        console.log(response.data);

        if (response.data.status === 'success') {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            html: `
              <p>Visitor Details</p>
              <br />
              <p>Name: ${response.data.first_name} ${
              response.data.last_name
            }</p>
              <p>Position: ${
                response.data.company_role ? response.data.company_role : 'NA'
              }</p>
              <p>Company Name: ${
                response.data.company_name ? response.data.company_name : 'NA'
              }</p>
              `,
            timer: 1200,
            timerProgressBar: true,
            showConfirmButton: false,
            willClose: () => {
              setQrscan('');
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              setQrscan('');
            }

            try {
              await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_KEY}/scan-log`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data: {
                  uid: userID,
                  visitor_id: response.data.uid,
                  first_name: response.data.first_name,
                  last_name: response.data.last_name,
                  company_name: response.data.company_name
                    ? response.data.company_name
                    : 'N/A',
                  company_role: response.data.company_role
                    ? response.data.company_role
                    : 'N/A',
                  status: response.data.status,
                  message: response.data.message,
                },
              });
            } catch (error) {
              console.error('Error logging scan:', error);
              setScanError(error);
            }
          });
        }
      } catch (error) {
        if (error) {
          setScanError(error);
        }
      } finally {
        setIsLoading(false); // Stop loader
        if (textFieldRef.current) {
          textFieldRef.current.focus(); // Refocus the TextField
        }
      }
    }
  };

  useEffect(() => {
    if (qrscan) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrscan]);

  useEffect(() => {
    if (scanError) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        html: `${scanError.response.data.error}`,
        timer: 1200,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          setQrscan('');
        },
      });
    }
  }, [scanError]);
  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <TextField
          autoFocus
          placeholder="Scan QR Code"
          fullWidth
          value={qrscan}
          onFocus={() => {
            setBool(true);
          }}
          inputProps={{
            readOnly: bool,
          }}
          onChange={(e) => handleFocus({ value: e.target.value })}
          onKeyDown={() => setBool(false)}
        />
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default QrScanner;
